import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgModule} from '@angular/core';
import {config} from '@fortawesome/fontawesome-svg-core';
import {
    faAddressBook, faAlignJustify, faAlignLeft, faAmbulance, faAngleDown, faAngleRight, faAngleUp, faArchive,
    faArrowCircleRight, faArrowLeft, faArrowRight, faArrowRightArrowLeft, faArrowsAlt, faBalanceScale, faBars, faBed,
    faBookmark, faCalculator, faCaretDown, faCaretLeft, faCaretRight, faCaretUp, faChartLine, faCheck, faCheckCircle,
    faCheckSquare, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircle, faCircleCheck, faCity, faCogs,
    faComment, faCopy, faCrosshairs, faDownload, faEdit, faEllipsisH, faEllipsisV, faEnvelope, faEraser, faEuroSign,
    faExclamation, faExclamationCircle, faExclamationTriangle, faExternalLinkAlt, faEye, faFeatherAlt, faFile,
    faFileAlt, faFileImport, faFileInvoiceDollar, faFilter, faGlobe, faHome, faInfoCircle, faKey, faLaptop, faLink,
    faLinkSlash, faLock, faLockOpen, faLowVision, faMagnifyingGlass, faMapMarkerAlt, faMinus, faMobileAlt,
    faNetworkWired, faPaperclip, faPencil, faPencilAlt, faPenToSquare, faPhone, faPlus, faPlusCircle, faPlusMinus,
    faPrint, faReply, faReplyAll, faRotate, faRss, faSearch, faShareAlt, faSlash, faSlidersH, faSort, faSortDown,
    faSortUp, faSpinner, faSyncAlt, faTachometerAlt, faTag, faThLarge, faThList, faTimes, faTimesCircle, faTint,
    faToolbox, faTrash, faTrashAlt, faUndo, faUniversity, faUser, faUsers
} from '@fortawesome/free-solid-svg-icons';
import {CommonModule} from '@angular/common';
import {
    faBookmark as farBookmark, faBuilding as farBuilding, faCalendar as farCalendar, faCalendarAlt as farCalendarAlt,
    faChartBar as farChartBar, faCheckCircle as farCheckCircle, faCheckSquare as farCheckSquare, faCopy as farCopy,
    faEdit as farEdit, faEnvelope as farEnvelope, faEye as farEye, faFile as farFile, faFileAlt as farFileAlt,
    faImage as farImage, faMap as farMap, faNewspaper as farNewspaper, faSun as farSun, faTrashAlt as farTrashAlt,
    faUser as farUser, faWindowMaximize as farWindowMaximize
} from '@fortawesome/free-regular-svg-icons';
import {AppFaShortcutComponent} from '@shared/fontawesome/shortcut/fontawesome-shortcut.component';
import {
    faChrome as fabChrome, faFacebookSquare as fabFacebookSquare, faFirefox as fabFirefox, faTwitter as fabTwitter
} from '@fortawesome/free-brands-svg-icons';
import {
    faAlignJustify as falAlignJustify, faArrowDownArrowUp as falArrowDownArrowUp,
    faArrowDownToBracket as falArrowDownToBracket, faArrowLeft as falArrowLeft, faArrowRight as falArrowRight,
    faArrowsLeftRight as falArrowsLeftRight, faBed as falBed, faBolt as falBolt, faBuilding as falBuilding,
    faBuildingColumns as falBuildingColumns, faCamera as falCamera, faCloudArrowUp as falCloudArrowUp,
    faCubes as falCubes, faDown as falDown, faEdit as falEdit, faEnvelope as falEnvelope, faExpandWide as falExpandWide,
    faEye as falEye, faFaucetDrip as falFaucetDrip, faFile as falFile, faFileAlt as falFileAlt,
    faFileInvoiceDollar as falFileInvoiceDollar, faGarage as falGarage, faHouse as falHouse, faImages as falImages,
    faLaptop as falLaptop, faLock as falLock, faMapLocationDot as falMapLocationDot, faMedal as falMedal,
    faPrint as falPrint, faPrintMagnifyingGlass as falPrintMagnifyingGlass, faPhone as falPhone,
    faReplyAll as falReplyAll, faRoad as falRoad, faRotate as falRotate, faRss as falRss, faShareAlt as falShareAlt,
    faSort as falSort, faSquareParking as falSquareParking, faStoreAlt as falStoreAlt, faThLarge as falThLarge,
    faThList as falThList, faTrash as falTrash, faTrashAlt as falTrashAlt, faUmbrellaBeach as falUmbrellaBeach,
    faUp as falUp, faUpDownLeftRight as falUpDownLeftRight, faUser as falUser
} from '@fortawesome/pro-light-svg-icons';
import {
    faBullseyePointer as farBullseyePointer, faChartLineUp as farChartLineUp, faStopwatch as farStopwatch,
    faTableLayout as farTableLayout, faThumbsUp as farThumbsUp
} from '@fortawesome/pro-regular-svg-icons';
import {faImage as fatImage, faNewspaper as fatNewspaper} from '@fortawesome/pro-thin-svg-icons';

@NgModule({
    declarations: [AppFaShortcutComponent],
    exports: [AppFaShortcutComponent, FontAwesomeModule],
    imports: [CommonModule, FontAwesomeModule],
})
export class FaModule {
    constructor(faIconLibrary: FaIconLibrary) {
        config.autoAddCss = false;
        faIconLibrary.addIcons(faAddressBook);
        faIconLibrary.addIcons(faAlignJustify, falAlignJustify);
        faIconLibrary.addIcons(faAlignLeft);
        faIconLibrary.addIcons(faAmbulance);
        faIconLibrary.addIcons(faAngleDown);
        faIconLibrary.addIcons(faAngleRight);
        faIconLibrary.addIcons(faAngleUp);
        faIconLibrary.addIcons(faArchive);
        faIconLibrary.addIcons(faArrowCircleRight);
        faIconLibrary.addIcons(falArrowDownArrowUp);
        faIconLibrary.addIcons(falArrowDownToBracket);
        faIconLibrary.addIcons(faArrowLeft, falArrowLeft);
        faIconLibrary.addIcons(faArrowRight, falArrowRight);
        faIconLibrary.addIcons(faArrowRightArrowLeft);
        faIconLibrary.addIcons(faArrowsAlt);
        faIconLibrary.addIcons(falArrowsLeftRight);
        faIconLibrary.addIcons(faBalanceScale);
        faIconLibrary.addIcons(faBars);
        faIconLibrary.addIcons(faBed, falBed);
        faIconLibrary.addIcons(falBolt);
        faIconLibrary.addIcons(faBookmark, farBookmark);
        faIconLibrary.addIcons(falBuilding, farBuilding);
        faIconLibrary.addIcons(falBuildingColumns);
        faIconLibrary.addIcons(farBullseyePointer);
        faIconLibrary.addIcons(faCalculator);
        faIconLibrary.addIcons(farCalendar);
        faIconLibrary.addIcons(farCalendarAlt);
        faIconLibrary.addIcons(falCamera);
        faIconLibrary.addIcons(faCaretDown);
        faIconLibrary.addIcons(faCaretLeft);
        faIconLibrary.addIcons(faCaretRight);
        faIconLibrary.addIcons(faCaretUp);
        faIconLibrary.addIcons(farChartBar);
        faIconLibrary.addIcons(faChartLine);
        faIconLibrary.addIcons(farChartLineUp);
        faIconLibrary.addIcons(faCheckSquare);
        faIconLibrary.addIcons(faChevronDown);
        faIconLibrary.addIcons(faChevronLeft);
        faIconLibrary.addIcons(faChevronRight);
        faIconLibrary.addIcons(faChevronUp);
        faIconLibrary.addIcons(fabChrome);
        faIconLibrary.addIcons(faCheck);
        faIconLibrary.addIcons(faCheckCircle, farCheckCircle);
        faIconLibrary.addIcons(farCheckSquare);
        faIconLibrary.addIcons(faCircle);
        faIconLibrary.addIcons(faCircleCheck);
        faIconLibrary.addIcons(faCity);
        faIconLibrary.addIcons(falCloudArrowUp);
        faIconLibrary.addIcons(faCogs);
        faIconLibrary.addIcons(faComment);
        faIconLibrary.addIcons(faCopy, farCopy);
        faIconLibrary.addIcons(faCrosshairs);
        faIconLibrary.addIcons(falCubes);
        faIconLibrary.addIcons(faDownload);
        faIconLibrary.addIcons(falDown);
        faIconLibrary.addIcons(faEdit, falEdit, farEdit);
        faIconLibrary.addIcons(faEllipsisH);
        faIconLibrary.addIcons(faEllipsisV);
        faIconLibrary.addIcons(faEnvelope, farEnvelope, falEnvelope);
        faIconLibrary.addIcons(faEraser);
        faIconLibrary.addIcons(faEuroSign);
        faIconLibrary.addIcons(faExclamation);
        faIconLibrary.addIcons(faExclamationCircle);
        faIconLibrary.addIcons(faExclamationTriangle);
        faIconLibrary.addIcons(falExpandWide);
        faIconLibrary.addIcons(faExternalLinkAlt);
        faIconLibrary.addIcons(faEye, falEye, farEye);
        faIconLibrary.addIcons(fabFacebookSquare);
        faIconLibrary.addIcons(falFaucetDrip);
        faIconLibrary.addIcons(faFeatherAlt);
        faIconLibrary.addIcons(faFile, falFile, farFile);
        faIconLibrary.addIcons(faFileAlt, falFileAlt, farFileAlt);
        faIconLibrary.addIcons(faFileImport);
        faIconLibrary.addIcons(faFileInvoiceDollar, falFileInvoiceDollar);
        faIconLibrary.addIcons(faFilter);
        faIconLibrary.addIcons(fabFirefox);
        faIconLibrary.addIcons(falGarage);
        faIconLibrary.addIcons(faGlobe);
        faIconLibrary.addIcons(falHouse);
        // @todo : nouveau nom faHouse
        faIconLibrary.addIcons(faHome);
        faIconLibrary.addIcons(farImage, fatImage);
        faIconLibrary.addIcons(falImages);
        faIconLibrary.addIcons(faInfoCircle);
        faIconLibrary.addIcons(faKey);
        faIconLibrary.addIcons(faLaptop, falLaptop);
        faIconLibrary.addIcons(faLink);
        faIconLibrary.addIcons(faLinkSlash);
        faIconLibrary.addIcons(faLock, falLock);
        faIconLibrary.addIcons(faLockOpen);
        faIconLibrary.addIcons(faLowVision);
        faIconLibrary.addIcons(faMagnifyingGlass);
        faIconLibrary.addIcons(farMap);
        faIconLibrary.addIcons(falMapLocationDot);
        faIconLibrary.addIcons(faMapMarkerAlt);
        faIconLibrary.addIcons(falMedal);
        faIconLibrary.addIcons(faMinus);
        faIconLibrary.addIcons(faMobileAlt);
        faIconLibrary.addIcons(faNetworkWired);
        faIconLibrary.addIcons(farNewspaper, fatNewspaper);
        faIconLibrary.addIcons(faPaperclip);
        faIconLibrary.addIcons(faPencil);
        faIconLibrary.addIcons(faPencilAlt);
        faIconLibrary.addIcons(faPenToSquare);
        faIconLibrary.addIcons(faPhone, falPhone);
        faIconLibrary.addIcons(faPlus);
        faIconLibrary.addIcons(faPlusCircle);
        faIconLibrary.addIcons(faPlusMinus);
        faIconLibrary.addIcons(faPrint, falPrint);
        faIconLibrary.addIcons(falPrintMagnifyingGlass);
        faIconLibrary.addIcons(faReply);
        faIconLibrary.addIcons(faReplyAll, falReplyAll);
        faIconLibrary.addIcons(falRoad);
        faIconLibrary.addIcons(faRotate, falRotate);
        faIconLibrary.addIcons(faRss, falRss);
        faIconLibrary.addIcons(faSearch);
        faIconLibrary.addIcons(faShareAlt, falShareAlt);
        faIconLibrary.addIcons(faSlash);
        faIconLibrary.addIcons(faSlidersH);
        faIconLibrary.addIcons(faSort, falSort);
        faIconLibrary.addIcons(faSortDown);
        faIconLibrary.addIcons(faSortUp);
        faIconLibrary.addIcons(faSpinner);
        faIconLibrary.addIcons(falSquareParking);
        faIconLibrary.addIcons(falStoreAlt);
        faIconLibrary.addIcons(farSun);
        faIconLibrary.addIcons(faSyncAlt);
        faIconLibrary.addIcons(farStopwatch);
        faIconLibrary.addIcons(farTableLayout);
        faIconLibrary.addIcons(faTachometerAlt);
        faIconLibrary.addIcons(faTag);
        faIconLibrary.addIcons(faThLarge, falThLarge);
        faIconLibrary.addIcons(faThList, falThList);
        faIconLibrary.addIcons(farThumbsUp);
        faIconLibrary.addIcons(faTimes);
        faIconLibrary.addIcons(faTimesCircle);
        faIconLibrary.addIcons(faTint);
        faIconLibrary.addIcons(faToolbox);
        faIconLibrary.addIcons(faTrash, falTrash);
        faIconLibrary.addIcons(faTrashAlt, falTrashAlt, farTrashAlt);
        faIconLibrary.addIcons(fabTwitter);
        faIconLibrary.addIcons(falUmbrellaBeach);
        faIconLibrary.addIcons(faUndo);
        faIconLibrary.addIcons(faUniversity);
        faIconLibrary.addIcons(falUp);
        faIconLibrary.addIcons(falUpDownLeftRight);
        faIconLibrary.addIcons(faUser, falUser, farUser);
        faIconLibrary.addIcons(faUsers);
        faIconLibrary.addIcons(farWindowMaximize);
    }
}
