@if (estimation$ | async; as estimation) {
  <div class="tw-py-4 tw-flex-1">
    @if (estimation.isArchived()) {
      <div class="tw-max-w-[40rem] tw-mx-auto tw-rounded-lg tw-bg-blue-50 tw-p-4 tw-space-y-4">
        <h3 class="tw-text-center tw-font-semi-bold tw-text-gray-900">
          Génération {{ estimation.isEvaluation() ? 'du rapport d\'évaluation' : 'de l\'avis de valeur' }}
        </h3>

        <div class="tw-text-base tw-text-gray-600">
          Aucun {{ estimation.isEvaluation() ? 'rapport d\'évaluation' : 'avis de valeur' }} n'a été créé pour ce bien
          en estimation.
        </div>

        <p class="tw-text-center tw-text-sm">
          Allez dans la partie
          <a [routerLink]="'/app/estimations/' + estimation.id.toString() + '/lifetime'" class="tw-font-semibold">
            "Dossier" de votre bien en estimation
          </a>
          afin de voir les actions disponibles.
        </p>
      </div>
    } @else {
      @if (estimation.canEditRapport()) {
        <form class="tw-form-2col" #rapportForm="ngForm" (submit)="createRapport(estimation)">
          <div class="tw-form-2col-part">
            <div class="tw-form-left-col">
              <h3 class="tw-form-left-col-title">Choix du modèle</h3> <p class="tw-form-left-col-explain"></p>
            </div>

            <div class="tw-form-right-col">
              <div class="tw-flex tw-flex-wrap tw-gap-4 lg:tw-gap-8 2xl:tw-gap-12">
                <div [appFormError]="rapportSelect.formInput" class="tw-w-60">
                  <label>Rapport *</label>

                  <app-template-select [options]="{name: 'modelRapport', placeholder: 'Sélectionnez un rapport', required: true, selectFirst: true}"
                                       #rapportSelect="templateSelect" [categories]="rapportCategories"
                                       (selected)="selectRapportTemplate($event)"/>
                </div>

                <div class="tw-w-[22rem]">
                  <label>Courrier introductif</label>

                  <app-template-select [options]="{name: 'modelCourrier', placeholder: 'Sélectionnez un courrier introductif au rapport'}"
                                       [categories]="courrierCategories" (selected)="courrierTemplate = $event"/>
                </div>
              </div>

              @if (rapportTemplate) {
                @if (rapportTemplate.category.isEstimationEvaluationCityscan() && !advertising) {
                  <div class="tw-pt-8 tw-text-base">
                    La génération du rapport peut prendre quelques instants afin de collecter toutes les informations
                    depuis <app-noteo-icon-text-cityscan/>.
                  </div>
                }
              }

              @if (advertising) {
                <p class="tw-w-full tw-mt-12 tw-pt-4 tw-text-base tw-font-semibold tw-text-center">
                  @switch (advertising) {
                    @case (codeCategories.ESTIMATION_AVIS_VALEUR) {
                      Découvrez l'évaluation de votre bien en estimation enrichie des données
                      <app-noteo-icon-text-cityscan/>
                      <app-noteo-contact [module]="moduleCityscan" [options]="{withModuleInactif: false}"
                                         class="tw-block tw-mt-4"/>
                    }

                    @case (codeCategories.ESTIMATION_EVALUATION) {
                      <ng-container *ngTemplateOutlet="cityscan"/>
                    }

                    @case (codeCategories.ESTIMATION_EVALUATION_CITYSCAN) {
                      <ng-container *ngTemplateOutlet="cityscan"/>
                    }

                    @default {
                      Besoin d'une aide ou d'une amélioration ?<br/><br/>
                      <app-noteo-contact [module]="moduleCityscan" class="tw-block tw-mt-4"/>
                    }
                  }
                </p>

                <ng-template #cityscan>
                  Découvrez comment améliorer votre rapport d'évaluation Camilla en intégrant les données provenant de
                  <app-noteo-icon-text-cityscan/>
                  <app-noteo-contact [module]="moduleCityscan" class="tw-block tw-mt-4"/>
                </ng-template>
              }
            </div>
          </div>

          @if (indicatorsSections$ | async; as indicatorsSections) {
            @if (indicatorsSections.length > 0) {
              <div class="tw-form-2col-part">
                <div class="tw-form-left-col">
                  <h3 class="tw-form-left-col-title">Indicateurs <app-noteo-icon-text-cityscan/></h3>

                  <p class="tw-form-left-col-explain">
                    Décochez les indicateurs n'étant pas pertinents pour votre évaluation, <app-noteo-icon-text-noty/>
                    s'occupe du reste.<br><br>Les indicateurs grisés seront disponibles dans une prochaine version. Nous
                    vous tiendrons informés de leur disponibilité.
                  </p>
                </div>

                <div class="tw-form-right-col tw-divide-y tw-space-y-8">
                  @for (indicatorsSection of indicatorsSections; track indicatorsSection; let first = $first) {
                    <div [class]="first ? '' : 'tw-pt-8'">
                      <div class="tw-flex tw-gap-3">
                        <input type="checkbox" [id]="indicatorsSection.uuid"
                               [indeterminate]="indicatorsSection.isCrossed()" [ngModel]="indicatorsSection.isChecked()"
                               [name]="'indicators-section-' + indicatorsSection.uuid"
                               (ngModelChange)="updateIndicatorsSectionStatut(indicatorsSection)"
                               class="tw-input-checkbox" [disabled]="indicatorsSection.isAllIndicatorsDisabled()"/>

                        <label [ngClass]="indicatorsSection.isAllIndicatorsDisabled() ? 'tw-cursor-default tw-opacity-75' : 'tw-cursor-pointer'"
                               class="tw-text-gray-700 tw-text-lg tw-leading-none tw-font-light"
                               [for]="indicatorsSection.uuid">{{ indicatorsSection.title }}</label>
                      </div>

                      <app-form-checkbox-list [list]="indicatorsSection.indicators" [options]="indicatorsOptions"
                                              (selected)="selectIndicators(indicatorsSection, $event)"
                                              class="tw-mt-4 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 2xl:tw-grid-cols-3 tw-gap-2"
                                              [model]="selectedIndicators[indicatorsSection.uuid]"/>
                    </div>
                  }
                </div>
              </div>
            }
          } @else {
            <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
          }

          <button type="submit" class="tw-hidden"></button>
        </form>
      } @else {
        <app-estimation-rapport-missing-data [estimation]="estimation"/>
      }
    }
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
}
