import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export default class ScreenSizeService {
    static readonly sizes = {XS: 'xs', SM: 'sm', MD: 'md', LG: 'lg', XL: 'xl', ['2XL']: '2xl'};
    static readonly widths = {SM: 640, MD: 768, LG: 1024, XL: 1280, ['2XL']: 1536};

    getSize(): string {
        if (this.test(ScreenSizeService.widths['2XL'])) {
            return ScreenSizeService.sizes['2XL'];
        } else if (this.test(ScreenSizeService.widths.XL)) {
            return ScreenSizeService.sizes.XL;
        } else if (this.test(ScreenSizeService.widths.LG)) {
            return ScreenSizeService.sizes.LG;
        } else if (this.test(ScreenSizeService.widths.MD)) {
            return ScreenSizeService.sizes.MD;
        } else if (this.test(ScreenSizeService.widths.SM)) {
            return ScreenSizeService.sizes.SM;
        }

        return ScreenSizeService.sizes.XS;
    }

    test(width: number): boolean {
        return matchMedia('(min-width: ' + width.toString() + 'px)').matches;
    }
}
