import {Component, inject} from '@angular/core';
import {UserService} from '@models/users/user/user.service';
import {Observable} from 'rxjs';
import User from '@models/users/user/user.model';
import {NgEstimationEvaluationComponent} from '@shared/angularJS/up-ng/estimations/estimation-evaluation.component';
import {AsyncPipe} from '@angular/common';
import {LoadModule} from '@shared/load/load.module';
import {AppNoteoIconTextNotestimComponent} from '@shared/noteo/icon-text/notestim/noteo-icon-text.notestim.component';
import {NoteoModule} from '@shared/noteo/noteo.module';
import {AppNoteoContactComponent} from '@shared/noteo/contact/noteo-contact.component';

@Component({
    // @todo Supprimer l'import de LoadModule pour préférer seulement AppLoader quand celui-ci sera standalone
    // @todo Supprimer l'import de NoteoModule pour préférer seulement AppNoteoContactComponent quand celui-ci sera standalone
    imports: [AppNoteoIconTextNotestimComponent, LoadModule, AsyncPipe, NgEstimationEvaluationComponent, NoteoModule],
    selector: 'layout-estimation-evaluation-root',
    standalone: true,
    templateUrl: 'layout-estimation-evaluation-root.component.html',
})
export class AppLayoutEstimationEvaluationRootComponent {
    private _userService = inject(UserService);

    get currentUser$(): Observable<User> {
        return this._userService.last$;
    }

    get moduleNotestim(): string {
        return AppNoteoContactComponent.modules.NOTESTIM;
    }
}
