export default `
<form name="$ctrl.editForm" ng-submit="$ctrl.save()" novalidate>
  <esk-button-save saving="$ctrl.saving"></esk-button-save>

  <fieldset class="evaluation-references">
    <legend>Méthode comparative</legend>

    <esk-estimation-references-crud estimation="$ctrl.estimation" options="$ctrl.options.crud"
                                    on-change="$ctrl.changeValeurVenaleM2"></esk-estimation-references-crud>

    <div class="row references-calculs">
      <!-- Prix moyen du m² -->
      <div class="col-sm-6 col-md-4 calculs-value">
        <label>Prix moyen du m² :</label>

        <h4 class="text-bold">
          {{$ctrl.estimation._esk.prixMoyenM2 |
          currency:'€':2 * ($ctrl.estimation._esk.prixMoyenM2 % 1 !== 0)}}
        </h4>

        <a uib-popover="Moyenne du prix au m² des références saisies." popover-trigger="'outsideClick'"
           popover-placement="top" class="btn btn-o btn-null"><span class="badge badge-info">?</span></a>
      </div>

      <!-- Surface habitable pondérée -->
      <div class="col-sm-6 col-md-4 calculs-value">
        <label>Surface habitable pondérée :</label>

        <h4 class="text-bold">
          {{$ctrl.estimation._esk.surfacePonderee |
          currency:'m²':2 * ($ctrl.estimation._esk.surfacePonderee % 1 !== 0)}}
        </h4>

        <button type="button" ng-click="$ctrl.updateValeurVenalePondereeCalculeeFromSurfacePonderee()"
                class="tw-btn-tertiary-info tw-mt-px tw-p-0 tw-text-xs">
          <fa-icon [icon]="'sync-alt'"></fa-icon>
        </button>

        <a uib-popover-template="'src/app/legacy/templates/estimations/components/item/evaluation/surface-ponderee-help.html'"
           popover-trigger="'outsideClick'" popover-placement="top" class="btn btn-o btn-null">
          <span class="badge badge-info">?</span>
        </a>
      </div>

      <!-- Valeur vénale pondérée calculée -->
      <div class="col-sm-12 col-md-4 calculs-value">
        <label>Valeur vénale pondérée calculée :</label>

        <h4 class="text-bold">
          {{$ctrl.estimation._esk.valeurVenalePondereeCalculee |
          currency:'€':2 * ($ctrl.estimation._esk.valeurVenalePondereeCalculee % 1 !== 0)}}
        </h4>

        <a uib-popover="Multiplication du prix moyen au m² et de la surface habitable pondérée."
           popover-trigger="'outsideClick'" popover-placement="top" class="btn btn-o btn-null">
          <span class="badge badge-info">?</span>
        </a>
      </div>
    </div>

    <div class="row">
      <!-- Prix du m² retenu -->
      <div class="form-group col-sm-6 col-md-4 col-lg-3 evaluation-help">
        <label for="estimation-valeur-venale-m2">Prix du m² retenu</label>

        <div class="input-group input-group-number input-group-currency">
          <input type="text" id="estimation-valeur-venale-m2" class="form-control"
                 ng-model="$ctrl.estimation.valeurVenaleM2" soq-only-digits="{float: true}"
                 ng-change="$ctrl.changeValeurVenaleComparaison()">

          <span class="input-group-addon">€</span>
        </div>

        <div class="help-popover">
          <a uib-popover="Récupération du prix moyen du m² provenant des références."
             popover-trigger="'outsideClick'" popover-placement="top" class="btn btn-o btn-null">
            <span class="badge badge-info">?</span>
          </a>
        </div>
      </div>

      <!-- Travaux au m² -->
      <div class="form-group col-sm-6 col-md-4 col-lg-3">
        <label for="estimation-travaux-m2">Travaux au m²</label>

        <div class="input-group input-group-number input-group-currency">
          <input type="text" id="estimation-travaux-m2" class="form-control"
                 soq-only-digits="{float: true}" ng-model="$ctrl.estimation.montantTravauxM2">

          <span class="input-group-addon">€</span>
        </div>
      </div>

      <!-- Valeur vénale comparaison -->
      <div class="form-group col-sm-6 col-md-4 col-lg-3 evaluation-help">
        <label for="estimation-valeur-venale-comparaison">Valeur vénale par comparaison</label>

        <div class="input-group input-group-number input-group-currency">
          <input type="text" id="estimation-valeur-venale-comparaison" class="form-control"
                 soq-only-digits="{float: true}" ng-change="$ctrl.changeValeurVenaleBrute()"
                 ng-model="$ctrl.estimation.valeurVenaleComparaison">

          <span class="input-group-addon">€</span>
        </div>

        <div class="help-popover">
          <a uib-popover="Multiplication de la surface habitable pondérée et du prix au m² retenu."
             popover-trigger="'outsideClick'" popover-placement="top" class="btn btn-o btn-null">
            <span class="badge badge-info">?</span>
          </a>
        </div>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <legend>Références en vente</legend>

    <div ng-if="$ctrl.currentEtude.settingsCityscan.enabled">
      <div class="tw-flex tw-justify-end tw-mb-4">
        <button type="button" class="btn btn-primary btn-o" ng-click="$ctrl.searchCityscanOnsaleReferences()">
          <fa-icon [icon]="'search'"></fa-icon> Rechercher des références
        </button>
      </div>

      <app-estimation-onsale-references-list [c-estimation-onsale-references$]="$ctrl.cEstimationOnsaleReferences$"
                                             (clicked)="$ctrl.onClickedEstimationOnsaleReference($event)"
                                             (reference-sorted)="$ctrl.estimationOnsaleReferenceSorted($event)">
      </app-estimation-onsale-references-list>
    </div>

    <div ng-if="!$ctrl.currentEtude.settingsCityscan.enabled"
         class="tw-pt-1 tw-text-base tw-text-center tw-font-semibold">
      Découvrez comment récupérer des références en vente depuis
      <app-noteo-icon-text-cityscan></app-noteo-icon-text-cityscan>
      <app-noteo-contact [module]="$ctrl.moduleCityscan" class="tw-block tw-mt-4"></app-noteo-contact>
    </div>
  </fieldset>

  <fieldset class="evaluation-precisions">
    <legend>Précisions</legend>

    <div class="row">
      <ng-switch on="!!$ctrl.estimation.mapMediaId" class="col-sm-6 precisions-map">
        <label>Carte de la situation géographique</label>

        <div ng-switch-default>
          <div class="map-upload-infos">
            <esk-upload-files-info type-file="file" info-client-filter="$ctrl.infoClientFilter"
                                   update-tasks-errors="$ctrl.updateTasksErrors" update-tasks="$ctrl.updateTasks">
            </esk-upload-files-info>
          </div>

          <soq-input-file ng-if="!$ctrl.options.map.loading" on-change="$ctrl.handleFileMap" files-max-size="10485760"
                          id="input_file" options="{multiple: false}" id-dnd-field="input_file" file-type="image">
          </soq-input-file>
        </div>

        <div ng-switch-when="true">
          <button type="button" class="btn btn-link" ng-click="$ctrl.viewMap()">
            <fa-icon [icon]="['far', 'eye']"></fa-icon> Voir la carte
          </button>

          <button type="button" class="btn btn-link" ng-click="$ctrl.removeMap()">
            <fa-icon [icon]="'trash-alt'"></fa-icon> Supprimer la carte
          </button>
        </div>
      </ng-switch>

      <div class="col-sm-6 form-group">
        <label>Date de visite</label>

        <esk-select-date model="$ctrl.estimation.dateVisite"></esk-select-date>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 form-group">
        <label>Caractères favorables</label>

        <app-ng2-textarea [text]="$ctrl.estimation.avantages" (changed)="$ctrl.estimation.avantages = $event">
        </app-ng2-textarea>
      </div>

      <div class="col-md-6 form-group">
        <label>Caractères défavorables</label>

        <app-ng2-textarea [text]="$ctrl.estimation.inconvenients" (changed)="$ctrl.estimation.inconvenients = $event">
        </app-ng2-textarea>
      </div>
    </div>

    <div class="form-group">
      <label>Réserves concernant l'environnement et la contamination</label>

      <app-ng2-textarea (changed)="$ctrl.estimation.reservesEnvironnementContamination = $event"
                        [text]="$ctrl.estimation.reservesEnvironnementContamination"></app-ng2-textarea>
    </div>
  </fieldset>

  <fieldset>
    <legend>Évaluation</legend>

    <div class="row">
      <!-- Valeur vénale brute -->
      <div class="form-group col-sm-6 col-md-4 col-lg-3 evaluation-help">
        <label for="estimation-valeur-venale-brute">Valeur vénale retenue du bien immobilier</label>

        <div class="input-group input-group-number input-group-currency">
          <input type="text" id="estimation-valeur-venale-brute" class="form-control"
                 soq-only-digits="{float: true}" ng-model="$ctrl.estimation.valeurVenaleBrute"
                 ng-change="$ctrl.changeValeurVenale()">

          <span class="input-group-addon">€</span>
        </div>

        <div class="help-popover">
          <a uib-popover="Récupération de la valeur vénale par comparaison."
             popover-trigger="'outsideClick'" popover-placement="top" class="btn btn-o btn-null">
            <span class="badge badge-info">?</span>
          </a>
        </div>
      </div>

      <!-- Correctif de la valeur vénale -->
      <div class="form-group col-sm-6 col-md-4 col-lg-3">
        <label for="estimation-correctif-valeur-venale">Correctif</label>

        <div class="input-group input-group-number input-group-currency">
          <input type="text" id="estimation-correctif-valeur-venale" class="form-control"
                 soq-only-digits="{float: true, negative: true}"
                 ng-model="$ctrl.estimation.correctifValeurVenale" ng-change="$ctrl.changeValeurVenale()">

          <span class="input-group-addon">€</span>
        </div>
      </div>

      <!-- Valeur vénale -->
      <div class="form-group col-sm-6 col-md-4 col-lg-3 evaluation-help">
        <label for="estimation-valeur-venale">Valeur vénale du bien immobilier en l'état</label>

        <div class="input-group input-group-number input-group-currency">
          <input type="text" id="estimation-valeur-venale" class="form-control"
                 soq-only-digits="{float: true}" ng-model="$ctrl.estimation.valeurVenale">

          <span class="input-group-addon">€</span>
        </div>

        <div class="help-popover">
          <a uib-popover="Addition de la valeur vénale retenue du bien immobilier et du correctif."
             popover-trigger="'outsideClick'" popover-placement="top" class="btn btn-o btn-null">
            <span class="badge badge-info">?</span>
          </a>
        </div>
      </div>
    </div>

    <!-- Commentaires de la valeur vénale -->
    <div class="form-group margin-top-20">
      <textarea id="bien-urbanisme-comments" ng-model="$ctrl.estimation.valeurVenaleComments"
               placeholder="Saisir les informations complémentaires à la valeur vénale"
               class="form-control" rows="4"></textarea>
    </div>
  </fieldset>
</form>
`;
