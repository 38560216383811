import {
    AfterViewInit, Directive, ElementRef, HostListener, Inject, inject, Input, OnDestroy, Renderer2
} from '@angular/core';
import {CollectionSortableService} from '@shared/collection/sortable/collection.sortable.service';
import {ICollectionItem, ICollectionSortableOptions} from '@shared/collection/collection.interfaces';
import {Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {NgBienCollectionItem} from '@legacy/app/managers/ressources';

// @todo Vérifier tous les typages HTML*
@Directive({selector: '[appCollectionSortable]', standalone: true})
export class AppCollectionSortableDirective implements AfterViewInit, OnDestroy {
    // @todo static readonly ? Faire le tour
    static CLASS_DRAGGER = 'collection-sortable-dragger';
    static CLASS_DRAGOVER = 'collection-sortable-dragover';
    static CLASS_MAIN = 'collection-sortable';
    static CLASS_RELEASE = 'collection-sortable-release';
    static CLASS_MAIN_TABLE = 'collection-sortable-table';
    static CLASS_MAIN_UL = 'collection-sortable-ul';
    static ID_DRAGGER_PARENT_PREFIX = 'id-dragger-parent';
    static TEXT_DRAGGER = ':::';
    static TEXT_DRAGGER_TITLE = 'Modifier l\'ordre';
    static TEXT_RELEASE = 'Relâcher pour placer à cette position';
    static readonly initCollectionSortableOptions: ICollectionSortableOptions = {};

    private _collectionSortableService = inject(CollectionSortableService);
    private _elementRef = inject(ElementRef<HTMLElement>);
    private _renderer2 = inject(Renderer2);
    private _window: Window;
    private _currentUuid!: string;
    private _collectionItems!: ICollectionItem[];
    private _collectionName!: string;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: ICollectionSortableOptions = {...AppCollectionSortableDirective.initCollectionSortableOptions};

    constructor(@Inject('Window') window: Window) {
        this._window = window;
    }

    @Input()
    set appCollectionSortable([name, collectionItems, currentUuid]: [string, ICollectionItem[] | NgBienCollectionItem[], string]) {
        this._collectionItems = collectionItems as ICollectionItem[];
        this._collectionName = name;
        this._currentUuid = currentUuid;
        if (this._collectionName) {
            this._collectionSortableService.init(this._collectionName);
        }
    }

    @Input()
    set appCollectionSortableOptions(value: ICollectionSortableOptions) {
        this._options = {...AppCollectionSortableDirective.initCollectionSortableOptions, ...value};
    }

    ngAfterViewInit(): void {
        this.configParent();
        this.configElement();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    addDragger(withText: boolean): void {
        const divDragger = this._renderer2.createElement('div') as HTMLDivElement;

        if (withText) {
            const textDragger = this._renderer2.createText(AppCollectionSortableDirective.TEXT_DRAGGER) as HTMLElement;

            this._renderer2.setAttribute(divDragger, 'title', AppCollectionSortableDirective.TEXT_DRAGGER_TITLE);
            this._renderer2.appendChild(divDragger, textDragger);
        }

        this.addDraggerListItem(this._elementRef.nativeElement, divDragger);
        this.addDraggerTableRow(this._elementRef.nativeElement, divDragger);
    }

    // @todo Supprimer le paramètre "nativeElement" pour utiliser this._elementRef.nativeElement
    addDraggerListItem(nativeElement: HTMLElement, divDragger: HTMLDivElement): void {
        if (nativeElement.localName !== 'li') {
            return;
        }

        const divContainer = this._renderer2.createElement('div') as HTMLDivElement;

        this._renderer2.addClass(divContainer, AppCollectionSortableDirective.CLASS_DRAGGER);
        this._renderer2.appendChild(divContainer, divDragger);
        this._renderer2.insertBefore(nativeElement, divContainer, nativeElement.firstChild);
    }

    // @todo Supprimer le paramètre "nativeElement" pour utiliser this._elementRef.nativeElement
    addDraggerTableRow(nativeElement: HTMLElement, divDragger: HTMLDivElement): void {
        if ((nativeElement.firstChild as HTMLElement).localName !== 'td') {
            return;
        }

        const td = this._renderer2.createElement('td') as HTMLTableCellElement;

        this._renderer2.addClass(td, AppCollectionSortableDirective.CLASS_DRAGGER);
        this._renderer2.appendChild(td, divDragger);
        this._renderer2.insertBefore(nativeElement, td, nativeElement.firstChild);
    }

    addEvents(): void {
        this._renderer2.listen(this._elementRef.nativeElement, 'dragend', _ => this._collectionSortableService.dragend(this._collectionName));
        this._renderer2.listen(this._elementRef.nativeElement, 'dragenter', _ => this._collectionSortableService.dragenter(this._collectionName, this._currentUuid));
        this._renderer2.listen(this._elementRef.nativeElement, 'dragover', ($event: DragEvent): void => $event.preventDefault());
        this._renderer2.listen(this._elementRef.nativeElement, 'dragstart', _ =>
            this._collectionSortableService.start(this._collectionName, this._currentUuid, this._elementRef.nativeElement.parentElement!.id));
        this._renderer2.listen(this._elementRef.nativeElement, 'drop', ($event: DragEvent): void => {
            this._collectionSortableService.end(this._collectionName, this._currentUuid, this._collectionItems, this._elementRef.nativeElement.parentElement!.id);
            $event.preventDefault();
        });
    }

    addReleaseZone(): void {
        const divRelacher = this._renderer2.createElement('div') as HTMLDivElement;
        const divText = this._renderer2.createElement('div') as HTMLDivElement;
        const text = this._renderer2.createText(AppCollectionSortableDirective.TEXT_RELEASE) as HTMLElement;

        this._renderer2.appendChild(divText, text);
        this._renderer2.appendChild(divRelacher, divText);
        this._renderer2.addClass(divRelacher, AppCollectionSortableDirective.CLASS_RELEASE);
        this._renderer2.appendChild(this._elementRef.nativeElement, divRelacher);
    }

    configElement(): void {
        this.addDragger(!!this._currentUuid);
        if (!this._currentUuid) {
            return;
        }

        this._renderer2.addClass(this._elementRef.nativeElement, AppCollectionSortableDirective.CLASS_MAIN);
        this._renderer2.setAttribute(this._elementRef.nativeElement, 'draggable', 'true');
        this.addEvents();
        this.addReleaseZone();
        this._collectionSortableService.getDragenterUuids$(this._collectionName).pipe(
            filter(([idElementList,]: [string, string]): boolean => idElementList === this._elementRef.nativeElement.parentElement!.id),
            map(([, dragenterUuid]: [string, string]): string => dragenterUuid),
            map(dragenterUuid => dragenterUuid === this._currentUuid
                && this._collectionSortableService.isDragover(this._collectionName, this._currentUuid, this._elementRef.nativeElement.parentElement!.id)),
            takeUntil(this._onDestroy$),
        ).subscribe(isDragover => {
            if (isDragover) {
                this._renderer2.addClass(this._elementRef.nativeElement, AppCollectionSortableDirective.CLASS_DRAGOVER);
            } else {
                this._renderer2.removeClass(this._elementRef.nativeElement, AppCollectionSortableDirective.CLASS_DRAGOVER);
            }
        });
    }

    configParent(): void {
        if (!this._elementRef.nativeElement.parentElement!.id) {
            this._elementRef.nativeElement.parentElement!.id = (Math.random()).toString();
        }

        this.configParentList();
        this.configParentTable();
    }

    configParentList(): void {
        const list = this.getParentList();

        if (!list) {
            return;
        }

        this._renderer2.addClass(list, AppCollectionSortableDirective.CLASS_MAIN_UL);
    }

    configParentTable(): void {
        const table = this.getParentTable();

        if (!table) {
            return;
        }

        this._renderer2.addClass(table, AppCollectionSortableDirective.CLASS_MAIN_TABLE);

        const tHeadTr = this.getParentTableTHeadTr(table);

        if (!tHeadTr) {
            return;
        }

        const idDragger = AppCollectionSortableDirective.ID_DRAGGER_PARENT_PREFIX + '-' + this._elementRef.nativeElement.parentElement!.id;
        const th = tHeadTr.firstChild as HTMLTableCellElement;

        if (!th || th.id === idDragger) {
            return;
        }

        const thDragger = this._renderer2.createElement('th') as HTMLTableElement;

        if (this._options.tHeadWithDiv) {
            const thDraggerDiv = this._renderer2.createElement('div') as HTMLDivElement;

            thDraggerDiv.textContent = ' ';
            this._renderer2.appendChild(thDragger, thDraggerDiv);
        }

        this._renderer2.setAttribute(thDragger, 'id', idDragger);
        this._renderer2.insertBefore(tHeadTr, thDragger, th);
        this.configParentTableHeadColumnsWidth(tHeadTr);
    }

    configParentTableHeadColumnsWidth(trHead: HTMLTableRowElement = this.getParentTableTHeadTr()): void {
        Array.from(trHead?.children || [])
            .filter(element => !element.id?.startsWith(AppCollectionSortableDirective.ID_DRAGGER_PARENT_PREFIX))
            .filter(element => !element.className?.includes('tw-w-') && !element.className?.includes('tw-max-w-') && !element.className?.includes('tw-min-w-'))
            .forEach(element => {
                this._renderer2.removeStyle(element, 'width');

                const computedStyle = this._window.getComputedStyle(element);

                this._renderer2.setStyle(element, 'width', computedStyle.width);
            });
    }

    getParentList(): HTMLElement {
        if (this._elementRef.nativeElement.localName === 'li' && this._elementRef.nativeElement.parentElement!.localName === 'ul') {
            return this._elementRef.nativeElement.parentElement!;
        }

        return undefined!;
    }

    getParentTable(): HTMLElement {
        if (this._elementRef.nativeElement.localName === 'tr'
            && this._elementRef.nativeElement.parentElement!.localName === 'tbody'
            && this._elementRef.nativeElement.parentElement!.parentElement!.localName === 'table') {
            return this._elementRef.nativeElement.parentElement!.parentElement!;
        }

        return undefined!;
    }

    getParentTableTHeadTr(table = this.getParentTable()): HTMLTableRowElement {
        if (!table) {
            return undefined!;
        }

        const thead = table.firstChild as HTMLTableSectionElement;

        if (!thead || thead.localName !== 'thead') {
            return undefined!;
        }

        const tr = thead.firstChild as HTMLTableRowElement;

        return tr?.localName === 'tr' ? tr : undefined!;
    }

    @HostListener('window:resize')
    onResize(): void {
        this.configParentTableHeadColumnsWidth();
    }
}
