import {IDirectiveFactory, IModule, Injectable} from 'angular';
import {downgradeComponent} from '@angular/upgrade/static';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';
import {Ng2ImgComponent} from '@shared/angularJS/down-ng2/for-ng/img/ng2-img.component';
import {AppDiagGraphesComponent} from '@shared/diagnostic/graphes/diagnostic-graphes.component';
import {Ng2TinymceComponent} from '@shared/angularJS/down-ng2/for-ng/tinymce/ng2-tinymce.component';
import {AppEtudeRaisonSocialeComponent} from '@features/etudes/etude/raison-sociale/etude-raison-sociale.component';
import {AppContactCardComponent} from '@features/contacts/contact/card/contact.card.component';
import {
    AppDossierInotExportsBoxComponent
} from '@features/dossiers/dossier/inot/exports/box/dossier-inot-exports.box.component';
import {AppUsersCheckboxComponent} from '@features/users/checkbox/users-checkbox.component';
import {AppDictionaryCheckboxComponent} from '@features/dictionaries/dictionary/checkbox/dictionary-checkbox.component';
import {AppFormRangeSliderComponent} from '@shared/form/range-slider/form.range-slider.component';
import {
    AppNoteoIconTextCityscanComponent
} from '@shared/noteo/icon-text/cityscan/noteo-icon-text.cityscan.component';
import {AppNoteoIconTextNotyComponent} from '@shared/noteo/icon-text/noty/noteo-icon-text.noty.component';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {AppFaShortcutComponent} from '@shared/fontawesome/shortcut/fontawesome-shortcut.component';
import {AppEmailStatutComponent} from '@features/emails/email/statut/email.statut.component';
import {
    AppEmailRecipientStatutComponent
} from '@features/emails/email/recipients/recipient/statut/email-recipient.statut.component';
import {AppFormSwitchComponent} from '@shared/form/switch/form.switch.component';
import {AppUserCartoucheComponent} from '@features/users/user/cartouche/user.cartouche.component';
import {TextPhoneComponent} from '@shared/texts/phones/phone/text-phone.component';
import {AppDossiersViewChoiceComponent} from '@features/dossiers/view-choice/dossiers-view-choice.component';
import {Ng2TextareaComponent} from '@shared/angularJS/down-ng2/for-ng/textarea/ng2-textarea.component';
import {Ng2DateComponent} from '@shared/angularJS/down-ng2/for-ng/date/ng2-date.component';
import {AppVenteStatutComponent} from '@features/ventes/vente/statut/vente-statut.component';
import {AppVentePriceComponent} from '@features/ventes/vente/price/vente-price.component';
import {AppQuartiersSelectionComponent} from '@features/quartiers/selection/quartiers-selection.component';
import {AppNoteoContactComponent} from '@shared/noteo/contact/noteo-contact.component';
import {
    AppEstimationOnsaleReferencesListComponent
} from '@features/estimations/estimation/onsale-references/list/estimation.onsale-references-list.component';
import {AppEmailPopoverComponent} from '@features/emails/email/popover/email.popover.component';
import {AppReportPopoverComponent} from '@features/reports/report/popover/report.popover.component';
import {
    AppEstimationReferencesListComponent
} from '@features/estimations/estimation/references/list/estimation-references-list.component';
import {AppVentePriceM2Component} from '@features/ventes/vente/price/m2/vente-price-m2.component';

export default (eskimmo: IModule): void => {
    // Features
    eskimmo
        .directive('appContactCard', downgradeComponent({component: AppContactCardComponent}) as Injectable<IDirectiveFactory>)
        .directive('appDiagGraphes', downgradeComponent({component: AppDiagGraphesComponent}) as Injectable<IDirectiveFactory>)
        .directive('appDictionaryCheckbox', downgradeComponent({component: AppDictionaryCheckboxComponent}) as Injectable<IDirectiveFactory>)
        .directive('appDossierInotExportsBox', downgradeComponent({component: AppDossierInotExportsBoxComponent}) as Injectable<IDirectiveFactory>)
        .directive('appDossiersViewChoice', downgradeComponent({component: AppDossiersViewChoiceComponent}) as Injectable<IDirectiveFactory>)
        .directive('appEmailPopover', downgradeComponent({component: AppEmailPopoverComponent}) as Injectable<IDirectiveFactory>)
        .directive('appEmailRecipientStatut', downgradeComponent({component: AppEmailRecipientStatutComponent}) as Injectable<IDirectiveFactory>)
        .directive('appEmailStatut', downgradeComponent({component: AppEmailStatutComponent}) as Injectable<IDirectiveFactory>)
        .directive('appEstimationOnsaleReferencesList', downgradeComponent({component: AppEstimationOnsaleReferencesListComponent}) as Injectable<IDirectiveFactory>)
        .directive('appEstimationReferencesList', downgradeComponent({component: AppEstimationReferencesListComponent}) as Injectable<IDirectiveFactory>)
        .directive('appEtudeRaisonSociale', downgradeComponent({component: AppEtudeRaisonSocialeComponent}) as Injectable<IDirectiveFactory>)
        .directive('appQuartiersSelection', downgradeComponent({component: AppQuartiersSelectionComponent}) as Injectable<IDirectiveFactory>)
        .directive('appReportPopover', downgradeComponent({component: AppReportPopoverComponent}) as Injectable<IDirectiveFactory>)
        .directive('appUserCartouche', downgradeComponent({component: AppUserCartoucheComponent}) as Injectable<IDirectiveFactory>)
        .directive('appUsersCheckbox', downgradeComponent({component: AppUsersCheckboxComponent}) as Injectable<IDirectiveFactory>)
        .directive('appVenteStatut', downgradeComponent({component: AppVenteStatutComponent}) as Injectable<IDirectiveFactory>)
        .directive('appVentePrice', downgradeComponent({component: AppVentePriceComponent}) as Injectable<IDirectiveFactory>)
        .directive('appVentePriceM2', downgradeComponent({component: AppVentePriceM2Component}) as Injectable<IDirectiveFactory>);

    // Shared
    eskimmo
        .directive('appFaShortcut', downgradeComponent({component: AppFaShortcutComponent}) as Injectable<IDirectiveFactory>)
        .directive('appFormRangeSlider', downgradeComponent({component: AppFormRangeSliderComponent}) as Injectable<IDirectiveFactory>)
        .directive('appFormSwitch', downgradeComponent({component: AppFormSwitchComponent}) as Injectable<IDirectiveFactory>)
        .directive('appLoader', downgradeComponent({component: AppLoaderComponent}) as Injectable<IDirectiveFactory>)
        .directive('appNg2Date', downgradeComponent({component: Ng2DateComponent}) as Injectable<IDirectiveFactory>)
        .directive('appNg2Img', downgradeComponent({component: Ng2ImgComponent}) as Injectable<IDirectiveFactory>)
        .directive('appNg2Textarea', downgradeComponent({component: Ng2TextareaComponent}) as Injectable<IDirectiveFactory>)
        .directive('appNg2Tinymce', downgradeComponent({component: Ng2TinymceComponent}) as Injectable<IDirectiveFactory>)
        .directive('appNoteoContact', downgradeComponent({component: AppNoteoContactComponent}) as Injectable<IDirectiveFactory>)
        .directive('appNoteoIconTextCityscan', downgradeComponent({component: AppNoteoIconTextCityscanComponent}) as Injectable<IDirectiveFactory>)
        .directive('appNoteoIconTextNoty', downgradeComponent({component: AppNoteoIconTextNotyComponent}) as Injectable<IDirectiveFactory>)
        .directive('appTextPhone', downgradeComponent({component: TextPhoneComponent}) as Injectable<IDirectiveFactory>)
        .directive('faIcon', downgradeComponent({component: FaIconComponent}) as Injectable<IDirectiveFactory>);
};
