import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {filter, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {IDropdownClicked} from '@shared/dropdown/dropdown.interfaces';
import CEstimationReferences
    from '@models/estimations/estimation/references/collection/estimation-references.collection.model';
import EstimationReference from '@models/estimations/estimation/references/reference/estimation-reference.model';
import {IEstimationReferencesListOptions} from '@features/estimations/estimations.interfaces';
import {
    CEstimationReferencesService
} from '@models/estimations/estimation/references/collection/estimation-references.collection.service';
import {
    EstimationReferenceDropdownComponent
} from '@features/estimations/estimation/references/reference/dropdown/estimation-reference.dropdown.component';
import {CollectionSortableService} from '@shared/collection/sortable/collection.sortable.service';
import EstimationOnsaleReference
    from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.model';
import {AsyncPipe, DatePipe} from '@angular/common';
import {TextCurrencyPipe} from '@shared/texts/currency/text-currency.pipe';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {LoadModule} from '@shared/load/load.module';
import {
    AppCommuneConvivialNameComponent
} from '@features/communes/commune/convivial-name/commune.convivial-name.component';
import {AppCollectionSortableDirective} from '@shared/collection/sortable/collection.sortable.directive';

// @todo Avant d'utiliser, finir la largeur des colonnes dans le template
@Component({
    imports: [
        AppCollectionSortableDirective,
        AppCommuneConvivialNameComponent,
        AsyncPipe,
        DatePipe,
        FaIconComponent,
        LoadModule,
        TextCurrencyPipe,
    ],
    selector: 'app-estimation-references-list',
    standalone: true,
    templateUrl: 'estimation-references-list.component.html',
})
export class AppEstimationReferencesListComponent implements OnDestroy, OnInit {
    @Output() readonly referenceAction = new EventEmitter<IDropdownClicked>();
    // Impossible de passer avec la ligne ci-dessous, une erreur dans la console apparait
    // Readonly referenceAction = output<IDropdownClicked>();
    static readonly actions = {
        ...EstimationReferenceDropdownComponent.actions,
        UPDATE_RANK: 'estimation_reference_update_rank',
    };
    readonly AppEstimationReferencesListName = 'AppEstimationReferencesList';
    private _collectionSortableService = inject(CollectionSortableService);
    private _cReferencesService = inject(CEstimationReferencesService);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _dropdownService = inject(DropdownService);
    private _cEstimationReferences$!: Observable<CEstimationReferences>;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IEstimationReferencesListOptions = {};
    private _pendingGetMore = false;
    private _superficieLabel!: string;

    get cEstimationReferences$(): Observable<CEstimationReferences> {
        return this._cEstimationReferences$;
    }

    @Input({required: true})
    set cEstimationReferences$(value$: Observable<CEstimationReferences>) {
        this._cEstimationReferences$ = value$;
    }

    get options(): IEstimationReferencesListOptions {
        return this._options;
    }

    @Input()
    set options(value: IEstimationReferencesListOptions) {
        this._options = {...value};
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    ngOnInit(): void {
        this._superficieLabel = this._dictionaryItemService.getOneSuperficieLabel(this._options.mainNature!);
        this._collectionSortableService.isInit$(this.AppEstimationReferencesListName).pipe(
            filter(isInit => isInit),
            switchMap(() => this._collectionSortableService.getMovedItem$(this.AppEstimationReferencesListName)),
            map(({item, idx}) => ({estimationReference: item as EstimationOnsaleReference, idx})),
            takeUntil(this._onDestroy$),
        ).subscribe(({estimationReference, idx}) => this.referenceAction.emit({
            action: AppEstimationReferencesListComponent.actions.UPDATE_RANK,
            value: {estimationReference, idx},
        }));
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked => this.referenceAction.emit(dropdownClicked));
    }

    ngOnDestroy(): void {
        this._collectionSortableService.delete(this.AppEstimationReferencesListName);
        this._onDestroy$.next();
    }

    clickOnEstimationReference(htmlButtonElement: HTMLButtonElement, estimationReference: EstimationReference): void {
        this._dropdownService.open(htmlButtonElement, {
            component: EstimationReferenceDropdownComponent,
            data: {estimationReference},
        });
    }

    getMore(cReferences: CEstimationReferences): void {
        this._pendingGetMore = true;
        this._cReferencesService.addNext$(cReferences).pipe(take(1)).subscribe({
            complete: () => this._pendingGetMore = false,
        });
    }
}
