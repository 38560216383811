import AModel from '@models/model.abstract';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import UserClientMetadata from '@models/users/user/client-metadata/user-client-metadata.model';
import Phone from '@models/phones/phone/phone.model';
import Site from '@models/sites/site/site.model';
import CPhones from '@models/phones/collection/phones.collection.model';
import UserPhoto from '@models/users/user/photo/user-photo.model';
import UserSettings from '@models/users/user/settings/user-settings.model';
import {ImageSizeConst} from '@shared/constants';
import {UrlService} from '@shared/texts/url/url.service';
import Person from '@models/contacts/person/person.model';

export default class User extends AModel {
    static readonly profils = {
        NEGOCIATEUR: 'NEGOCIATEUR',
        NOTAIRE: 'NOTAIRE',
    };
    static readonly roles = {
        ADMIN: 'ROLE_ADMIN',
        DEMANDEUR_LOCATION: 'ROLE_MODULE_DEMANDEUR_LOCATION',
        DEMANDEUR_VENTE: 'ROLE_MODULE_DEMANDEUR_VENTE',
        ESTIMATION_NOTESTIM_V2: 'ROLE_MODULE_ESTIMATION_NOTESTIM_V2',
        GRP_ESTIMATION: 'ROLE_GRP_ESTIMATION',
        GRP_ESTIMATION_AVIS_VALEUR: 'ROLE_GRP_ESTIMATION_AVIS_VALEUR',
        GRP_ESTIMATION_EVALUATION: 'ROLE_GRP_ESTIMATION_EVALUATION',
        GRP_LOCATION: 'ROLE_GRP_LOCATION',
        GRP_VENTE: 'ROLE_GRP_VENTE',
        NEGOCIATEUR: 'ROLE_NEGOCIATEUR',
        NOTAIRE: 'ROLE_NOTAIRE',
        NOTESTIM: 'ROLE_MODULE_NOTESTIM',
        PREVIOUS_ADMIN: 'ROLE_PREVIOUS_ADMIN',
        SIGNATURE_ELECTRONIQUE_SOQRATE: 'ROLE_MODULE_SIGNATURE_ELECTRONIQUE_SOQRATE',
        SIGNATURE_ELECTRONIQUE_YOUSIGN: 'ROLE_MODULE_SIGNATURE_ELECTRONIQUE_YOUSIGN',
    };
    static readonly cRoles = {
        DEMANDEUR: [User.roles.DEMANDEUR_LOCATION, User.roles.DEMANDEUR_VENTE],
        SIGNATURE_ELECTRONIQUE: [User.roles.SIGNATURE_ELECTRONIQUE_SOQRATE, User.roles.SIGNATURE_ELECTRONIQUE_YOUSIGN],
    };
    static readonly LINK_AVATAR = 'assets/images/icons/avatar-SEXE.svg';
    static readonly statuts = {NEW: AModel.STATUT_NEW};
    private _clientMetadata!: UserClientMetadata;
    private _convivialName!: string;
    private _convivialProfils!: string;
    private _cPhones!: CPhones;
    private _defaultNotaire!: User;
    private _emailAddress!: string;
    private _emailSignature!: string;
    private _etudeId!: number;
    private readonly _id: number;
    private _initiales!: string;
    private _isAvatarPhoto = false;
    private _linkClientMetadata!: string;
    private _linkEtude!: string;
    private _linkPhoto!: string;
    private _linkPhotoThumbnail!: string;
    private _linkSite!: string;
    private _linkVentes!: string;
    private _nom!: string;
    private _photo!: UserPhoto;
    private _prenom!: string;
    private _profils: string[] = [];
    private _roles: string[] = [];
    private _settings!: UserSettings;
    private _sexe!: string;
    private _site!: Site;
    private _titre!: DictionaryItem;
    private _username!: string;

    constructor(uuid: string, id?: number) {
        super(uuid);
        this._id = id!;
    }

    get clientMetadata(): UserClientMetadata {
        return this._clientMetadata;
    }

    set clientMetadata(value: UserClientMetadata) {
        this._clientMetadata = value;
    }

    get convivialName(): string {
        return this._convivialName;
    }

    get convivialProfils(): string {
        return this._convivialProfils;
    }

    get cPhones(): CPhones {
        return this._cPhones;
    }

    set cPhones(value: CPhones) {
        this._cPhones = value;
    }

    get defaultNotaire(): User {
        return this._defaultNotaire;
    }

    set defaultNotaire(value: User) {
        this._defaultNotaire = value;
    }

    get emailAddress(): string {
        return this._emailAddress;
    }

    set emailAddress(value: string) {
        this._emailAddress = value;
    }

    get emailSignature(): string {
        return this._emailSignature;
    }

    set emailSignature(value: string) {
        this._emailSignature = value;
    }

    get etudeId(): number {
        return this._etudeId;
    }

    set etudeId(value: number) {
        this._etudeId = value;
    }

    get id(): number {
        return this._id;
    }

    get initiales(): string {
        return this._initiales;
    }

    set initiales(value: string) {
        this._initiales = value;
    }

    get isAvatarPhoto(): boolean {
        return this._isAvatarPhoto;
    }

    get linkClientMetadata(): string {
        return this._linkClientMetadata;
    }

    set linkClientMetadata(value: string) {
        this._linkClientMetadata = value;
    }

    get linkEtude(): string {
        return this._linkEtude;
    }

    set linkEtude(value: string) {
        this._linkEtude = value;
    }

    get linkPhoto(): string {
        return this._linkPhoto;
    }

    get linkPhotoThumbnail(): string {
        return this._linkPhotoThumbnail;
    }

    get linkSite(): string {
        return this._linkSite;
    }

    set linkSite(value: string) {
        this._linkSite = value;
    }

    get linkVentes(): string {
        return this._linkVentes;
    }

    set linkVentes(value: string) {
        this._linkVentes = value;
    }

    get nom(): string {
        return this._nom;
    }

    set nom(value: string) {
        this._nom = value;
        this.setConvivialName();
    }

    get photo(): UserPhoto {
        return this._photo;
    }

    set photo(value: UserPhoto) {
        this._photo = value;
    }

    get prenom(): string {
        return this._prenom;
    }

    set prenom(value: string) {
        this._prenom = value;
        this.setConvivialName();
    }

    set profils(value: string[]) {
        this._profils = value || [];
        this.setConvivialProfils();
    }

    set roles(value: string[]) {
        this._roles = value;
    }

    get settings(): UserSettings {
        return this._settings;
    }

    set settings(value: UserSettings) {
        this._settings = value;
    }

    get sexe(): string {
        return this._sexe;
    }

    set sexe(value: string) {
        this._sexe = value;
        this.setConvivialProfils();
    }

    get site(): Site {
        return this._site;
    }

    set site(value: Site) {
        this._site = value;
    }

    get titre(): DictionaryItem {
        return this._titre;
    }

    set titre(value: DictionaryItem) {
        this._titre = value;
        this.setConvivialName();
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value;
    }

    getPhoneFixe(): string {
        return this.getPhoneNumber(Phone.types.FIXE);
    }

    getPhoneMobile(): string {
        return this.getPhoneNumber(Phone.types.MOBILE);
    }

    getPhoneMobileFirst(): string {
        return this.getPhoneMobile() || this.getPhoneFixe();
    }

    getPhoneNumber(type: string): string {
        return this.cPhones.getByType(type)?.numero;
    }

    hasProfil(profil: string): boolean {
        return this._profils.includes(profil);
    }

    hasRole(role: string): boolean {
        return this._roles.includes(role);
    }

    hasRoleAdmin(): boolean {
        return this.hasRole(User.roles.ADMIN);
    }

    hasRoleDemandeur(): boolean {
        return this.hasSomeRole(User.cRoles.DEMANDEUR);
    }

    hasRoleDemandeurLocation(): boolean {
        return this.hasRole(User.roles.DEMANDEUR_LOCATION);
    }

    hasRoleDemandeurVente(): boolean {
        return this.hasRole(User.roles.DEMANDEUR_VENTE);
    }

    hasRoleEstimationNotestimV2(): boolean {
        return this.hasRole(User.roles.ESTIMATION_NOTESTIM_V2);
    }

    hasRoleGrpEstimation(): boolean {
        return this.hasRole(User.roles.GRP_ESTIMATION);
    }

    hasRoleGrpEstimationAvisValeur(): boolean {
        return this.hasRole(User.roles.GRP_ESTIMATION_AVIS_VALEUR);
    }

    hasRoleGrpEstimationEvaluation(): boolean {
        return this.hasRole(User.roles.GRP_ESTIMATION_EVALUATION);
    }

    hasRoleGrpLocation(): boolean {
        return this.hasRole(User.roles.GRP_LOCATION);
    }

    hasRoleGrpVente(): boolean {
        return this.hasRole(User.roles.GRP_VENTE);
    }

    hasRoleNotaire(): boolean {
        return this.hasRole(User.roles.NOTAIRE);
    }

    hasRoleNotestim(): boolean {
        return this.hasRole(User.roles.NOTESTIM);
    }

    hasRolePreviousAdmin(): boolean {
        return this.hasRole(User.roles.PREVIOUS_ADMIN);
    }

    hasRoleSignatureElectronique(): boolean {
        return this.hasSomeRole(User.cRoles.SIGNATURE_ELECTRONIQUE);
    }

    hasRoleSignatureElectroniqueSoqrate(): boolean {
        return this.hasRole(User.roles.SIGNATURE_ELECTRONIQUE_SOQRATE);
    }

    hasRoleSignatureElectroniqueYousign(): boolean {
        return this.hasRole(User.roles.SIGNATURE_ELECTRONIQUE_YOUSIGN);
    }

    hasSomeRole(roles: string[]): boolean {
        return roles.some(role => this.hasRole(role));
    }

    isFemme(): boolean {
        return this.sexe === Person.sexes.FEMME;
    }

    // @todo Serait-ce intéressant de factoriser toutes les méthode setConvivialName() ?
    setConvivialName(): void {
        const convivialName: string[] = [];

        if (this.prenom) {
            convivialName.push(this.prenom);
        }

        if (this.nom) {
            convivialName.push(this.nom);
        }

        if (convivialName.length > 0 && this.titre) {
            convivialName.unshift(this.titre.shortLabel);
        }

        this._convivialName = convivialName.join(' ');
    }

    setConvivialProfils(): void {
        const convivialProfils: string[] = [];

        if (this.hasProfil(User.profils.NOTAIRE)) {
            convivialProfils.push('Notaire');
        }

        if (this.hasProfil(User.profils.NEGOCIATEUR)) {
            convivialProfils.push(this.isFemme() ? 'Négociatrice' : 'Négociateur');
        }

        this._convivialProfils = convivialProfils.join(' / ');
    }

    setLinkPhoto(linkPhoto?: string): void {
        this._isAvatarPhoto = !linkPhoto;
        if (this._isAvatarPhoto) {
            this._linkPhoto = User.LINK_AVATAR.replace('SEXE', this.sexe?.[0] || 'h');
            this._linkPhotoThumbnail = this._linkPhoto;
        } else {
            this._linkPhoto = linkPhoto!;
            this._linkPhotoThumbnail = UrlService.addQueryparamToUrl(this._linkPhoto, 'size', ImageSizeConst.THUMBNAIL);
            this._linkPhotoThumbnail = UrlService.addQueryparamToUrl(this._linkPhotoThumbnail, 'random', (Date.now() / 1000).toFixed(0));
        }
    }
}
