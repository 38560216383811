import {Component, Input, output} from '@angular/core';
import {ISearchOpenOptions} from '@shared/search/search.interfaces';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@Component({
    imports: [FaIconComponent],
    selector: 'app-search-open',
    standalone: true,
    templateUrl: 'search.open.component.html',
})
export class SearchOpenComponent {
    readonly clicked = output<void>();
    static readonly initSearchOpenOptions: ISearchOpenOptions = {placeHolder: 'Rechercher'};
    private _options: ISearchOpenOptions = {...SearchOpenComponent.initSearchOpenOptions};

    get options(): ISearchOpenOptions {
        return this._options;
    }

    @Input()
    set options(value: ISearchOpenOptions) {
        this._options = {...SearchOpenComponent.initSearchOpenOptions, ...value};
    }

    click(): void {
        this.clicked.emit();
    }
}
