import {IModule} from "angular";
import angularJS from '@shared/angularJS/global.ng';
import getEstimationsTemplates from '@legacy/app/estimations/templates';
import getEstimationsBoxRemis from '@legacy/app/estimations/components/box.remis';
import getEstimationsList from '@legacy/app/estimations/components/list';
import getEstimationLabelStatut from '@legacy/app/estimations/components/item/label/statut';
import getEstimationLabelValeur from '@legacy/app/estimations/components/item/label/valeur';
import getEstimationsModalBoxRemisCtrl from '@legacy/app/estimations/controllers/modals/box.remis';
import getEstimationsUtils from '@legacy/app/estimations/services/utils';
import getEstimationLifetimeCtrl from '@legacy/app/estimations/controllers_ponents/item/lifetime';
import getEstimationModalArchiveCtrl from '@legacy/app/estimations/controllers/modals/item/archive';
import getEstimationReferencesCrud from '@legacy/app/estimations/components/item/references/crud';
import getEstimationReferencesList from '@legacy/app/estimations/components/item/references/list';
import getEstimationsReferencesCriteriaCtrl from '@legacy/app/estimations/controllers_ponents/references/criteria';
import getEstimationEvaluationReferencesCriteriaCtrl from '@legacy/app/estimations/controllers_ponents/item/evaluation/references/criteria';
import getEstimationModalReferencesImportCtrl from '@legacy/app/estimations/controllers/modals/item/references.import';
import {NgSoqModalesManagerProvider} from '@legacy/app/soqrate/soqrate';
import getEstimationEvaluation from '@legacy/app/estimations/components/item/evaluation';
import getEstimationsReferences from '@legacy/app/estimations/components/references';
import getEstimationEvaluationOnsaleReferencesCriteriaCtrl
    from '@legacy/app/estimations/controllers_ponents/item/evaluation/onsale-references/criteria';

export default function getEstimations(): IModule {
    return (function (angular) {
        'use strict';

        const module = angular
            .module('esk.estimations', ['esk.biens'])
            .config(['SoqModalesManagerProvider', modales]);

        // Config / Run
        getEstimationsTemplates(module);

        // Services
        getEstimationsUtils(module);

        // Components
        getEstimationEvaluation(module);
        getEstimationLabelStatut(module);
        getEstimationLabelValeur(module);
        getEstimationReferencesCrud(module);
        getEstimationReferencesList(module);
        getEstimationsBoxRemis(module);
        getEstimationsList(module);
        getEstimationsModalBoxRemisCtrl(module);
        getEstimationsReferences(module);

        // Controllers
        getEstimationEvaluationOnsaleReferencesCriteriaCtrl(module);
        getEstimationEvaluationReferencesCriteriaCtrl(module);
        getEstimationLifetimeCtrl(module);
        getEstimationModalArchiveCtrl(module);
        getEstimationModalReferencesImportCtrl(module);
        getEstimationsReferencesCriteriaCtrl(module);

        return module;

        /**
         * Declare modales
         *
         * @param SoqModalesManagerProvider
         */
        function modales(soqModalesManagerProvider: NgSoqModalesManagerProvider) {
            soqModalesManagerProvider.addModale('EstimationModalArchive', {
                templateUrl: 'src/app/legacy/templates/estimations/controllers/modals/item/archive.html',
                controller: 'EstimationModalArchiveCtrl',
                backdrop: 'static'
            });
            soqModalesManagerProvider.addModale('EstimationModalReferencesImport', {
                templateUrl: 'src/app/legacy/templates/estimations/controllers/modals/item/references.import.html',
                controller: 'EstimationModalReferencesImportCtrl'
            });
            soqModalesManagerProvider.addModale('EstimationsModalBoxRemis', {
                templateUrl: 'src/app/legacy/templates/estimations/controllers/modals/box.remis.html',
                controller: 'EstimationsModalBoxRemisCtrl'
            });
        }
    })(angularJS);
}
