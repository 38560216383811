import angularJS from '@shared/angularJS/global.ng';
import {filter, round} from 'lodash';
import {IModule, IQService, IWindowService} from 'angular';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgEstimationManager, NgMediaManager} from '@legacy/app/managers/managers';
import {ToasterService} from '@shared/toaster/toaster.service';
import {NgEskTaskRunner} from '@legacy/app/eskimmo/eskimmo';
import {NgMedia} from '@legacy/app/managers/ressources';
import {MediaFactory} from '@models/medias/media/media.factory';
import {switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import {of, Subject} from 'rxjs';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {AppNoteoContactComponent} from '@shared/noteo/contact/noteo-contact.component';
import Estimation from '@models/estimations/estimation/estimation.model';
import {IDropdownClicked} from '@shared/dropdown/dropdown.interfaces';
import EstimationOnsaleReference
    from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.model';
import {
    EstimationOnsaleReferenceDropdownComponent
} from '@features/estimations/estimation/onsale-references/onsale-reference/dropdown/estimation.onsale-reference.dropdown.component';
import {
    CEstimationOnsaleReferencesFactory
} from '@models/estimations/estimation/onsale-references/collection/estimation-onsale-references.collection.factory';
import {
    EstimationOnsaleReferenceFactory
} from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.factory';
import {SlideOverService} from '@shared/slide-over/slide-over.service';
import {
    DCEstimationOnsaleReferenceSlideOverComponent
} from '@features/estimations/estimation/onsale-references/onsale-reference/slide-over/estimation.onsale-reference.slide-over.component';
import {IWindowHistoryState} from '@shared/routes/routes.interfaces';
import {EstimationService} from '@models/estimations/estimation/estimation.service';
import CEstimationReferences
    from '@models/estimations/estimation/references/collection/estimation-references.collection.model';

export default function getEstimationEvaluation(module: IModule): void {
    (function (angular) {
        'use strict';

        // Utiliser comme composant de routing
        module.component('eskEstimationEvaluation', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/estimations/components/item/evaluation.html',
        });

        /**
         * Controller for Estimations evaluation
         *
         * @param EstimationManager
         * @param $translate
         * @param SoqSweetAlert
         * @param $q
         * @param EskTaskRunner
         * @param MediaManager
         * @param Ng2RouterStateForNgService
         * @param Ng2ToasterService
         * @param Ng2MediaFactory
         * @param Ng2ModalService
         * @param Ng2EtudeService
         * @param Ng2CEstimationOnsaleReferencesFactory
         * @param Ng2EstimationOnsaleReferenceFactory
         * @param Ng2SlideOverService
         * @param $window
         * @param Ng2EstimationService
         */
        Controller.$inject = ['EstimationManager', '$translate', 'SoqSweetAlert', '$q', 'EskTaskRunner', 'MediaManager', 'Ng2RouterStateForNgService', 'Ng2ToasterService', 'Ng2MediaFactory', 'Ng2ModalService', 'Ng2EtudeService', 'Ng2CEstimationOnsaleReferencesFactory', 'Ng2EstimationOnsaleReferenceFactory', 'Ng2SlideOverService', '$window', 'Ng2EstimationService'];
        function Controller(this: any,
                            estimationManager: NgEstimationManager,
                            $translate: angular.translate.ITranslateService,
                            soqSweetAlert: NgSoqSweetAlert,
                            $q: IQService,
                            eskTaskRunner: NgEskTaskRunner,
                            mediaManager: NgMediaManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            ng2ToasterService: ToasterService,
                            ng2MediaFactory: MediaFactory,
                            ng2ModalService: ModalService,
                            ng2EtudeService: EtudeService,
                            ng2CEstimationOnsaleReferencesFactory: CEstimationOnsaleReferencesFactory,
                            ng2EstimationOnsaleReferenceFactory: EstimationOnsaleReferenceFactory,
                            ng2SlideOverService: SlideOverService,
                            $window: IWindowService,
                            ng2EstimationService: EstimationService) {
            const $ctrl = this;
            const _onDestroy$ = new Subject<void>();
            const refreshCEstimationOnsaleReferencesSource = new Subject<void>();

            $ctrl.$onDestroy = () => _onDestroy$.next();
            $ctrl.$onInit = $onInit;
            $ctrl.changeValeurVenaleM2 = changeValeurVenaleM2;
            $ctrl.changeValeurVenalePondereeCalculee = changeValeurVenalePondereeCalculee;
            $ctrl.changeValeurVenaleComparaison = changeValeurVenaleComparaison;
            $ctrl.changeValeurVenaleBrute = changeValeurVenaleBrute;
            $ctrl.changeValeurVenale = changeValeurVenale;
            $ctrl.handleFileMap = handleFileMap;
            $ctrl.estimationOnsaleReferenceSorted = estimationOnsaleReferenceSorted;
            $ctrl.onClickedEstimationOnsaleReference = onClickedEstimationOnsaleReference;
            $ctrl.removeMap = removeMap;
            $ctrl.save = save;
            $ctrl.searchCityscanOnsaleReferences = searchCityscanOnsaleReferences;
            $ctrl.updateValeurVenalePondereeCalculeeFromSurfacePonderee = updateValeurVenalePondereeCalculeeFromSurfacePonderee;
            $ctrl.viewMap = viewMap;
            $ctrl.moduleCityscan = AppNoteoContactComponent.modules.CITYSCAN;
            refreshCEstimationOnsaleReferencesSource.asObservable()
                .pipe(takeUntil(_onDestroy$))
                .subscribe(_ => $ctrl.cEstimationOnsaleReferences$ = ng2CEstimationOnsaleReferencesFactory.get$($ctrl.ng2Estimation));

            /**
             * Initialization method
             */
            function $onInit() {
                if (estimationManager.current.type.code !== Estimation.types.EVALUATION) {
                    ng2ToasterService.warning($translate.instant('estimation.type.access.TITLE'), $translate.instant('estimation.type.access.message.WRONG_TYPE', {type: estimationManager.current.type.code}));
                    ng2RouterStateForNgService.navigateByUrl('/app/estimations/' + estimationManager.current.id + '/edit/general');

                    return;
                }
                estimationManager.current._esk.surfacePonderee = 0;
                estimationManager.current.bien.pieces
                    .filter(piece => piece.isValid())
                    .map(piece => estimationManager.current._esk.surfacePonderee! += piece._esk.surfacePonderee!);
                if (estimationManager.current._esk.surfacePonderee === 0) {
                    const superficie = +(estimationManager.current.bien.superficie?.toString().replace(',', '.'));

                    if (superficie > 0) {
                        estimationManager.current._esk.surfacePonderee = superficie;
                    }
                }

                $ctrl.options = {
                    crud: {refresh: ($window.history?.state as IWindowHistoryState)?.extraParams?.addedReferences},
                    map: {},
                };
                $ctrl.estimation = estimationManager.current;
                $ctrl.ng2Estimation = ng2EstimationService.getCurrentFromNg();
                $ctrl.infoClientFilter = {type: 'evaluation_estimation_map', idDossier: $ctrl.estimation.id};
                angular.forEach(filter(eskTaskRunner.getTasks(), {infoClient: $ctrl.infoClientFilter}), function (task) {
                    task.promise.finally(function () {
                        // Conserver cet ordre car, si on quitte la vue et qu'on revient, la liste des tâches ne se met pas à jour, pas le même contexte pour eskUploadFilesInfo.
                        // De plus, il faut laisser cette méthode s'exécuter tel quelle, sinon elle n'est pas correctement initialisée.
                        $ctrl.updateTasks();
                    });
                });
                ng2EtudeService.last$.subscribe(currentEtude => $ctrl.currentEtude = currentEtude);
                refreshCEstimationOnsaleReferencesSource.next();
            }

            function changeValeurVenaleM2(cEstimationReferences: CEstimationReferences, init = false) {
                $ctrl.estimation._esk.prixMoyenM2 = cEstimationReferences.getPrixM2();
                $ctrl.changeValeurVenalePondereeCalculee(init)
            }

            /**
             * Operate valeurVenalePondereeCalculee
             *
             * @param init
             */
            function changeValeurVenalePondereeCalculee(init = false) {
                $ctrl.estimation._esk.valeurVenalePondereeCalculee = $ctrl.estimation._esk.prixMoyenM2 * $ctrl.estimation._esk.surfacePonderee;
                if (init) return;

                $ctrl.estimation.valeurVenaleM2 = round($ctrl.estimation._esk.prixMoyenM2, 2);
                $ctrl.changeValeurVenaleComparaison();
            }

            /**
             * Operate valeurVenaleComparaison
             */
            function changeValeurVenaleComparaison() {
                $ctrl.estimation.valeurVenaleComparaison = round($ctrl.estimation.valeurVenaleM2 * $ctrl.estimation._esk.surfacePonderee, 2);
                $ctrl.changeValeurVenaleBrute();
            }

            /**
             * Operate valeurVenaleBrute
             */
            function changeValeurVenaleBrute() {
                $ctrl.estimation.valeurVenaleBrute = $ctrl.estimation.valeurVenaleComparaison;
                $ctrl.changeValeurVenale();
            }

            /**
             * Operate valeurVenale
             */
            function changeValeurVenale() {
                $ctrl.estimation.valeurVenale = ($ctrl.estimation.valeurVenaleBrute || 0) + ($ctrl.estimation.correctifValeurVenale || 0);

                if ($ctrl.estimation.valeurVenale < 0) {
                    $ctrl.estimation.valeurVenale = 0;
                }
            }

            function estimationOnsaleReferenceSorted({estimationOnsaleReference, idx}: {
                estimationOnsaleReference: EstimationOnsaleReference,
                idx: number
            }) {
                ng2EstimationOnsaleReferenceFactory.updateRank$($ctrl.ng2Estimation, estimationOnsaleReference, idx + 1).pipe(take(1)).subscribe();
            }

            /**
             * Get file map
             *
             * @param files
             */
            function handleFileMap(files: File[]) {
                const media = mediaManager.create({file: files[0]});
                const task = eskTaskRunner.addCallbackTask({
                    method: media.upload,
                    context: media,
                    then: (media: NgMedia) => {
                        $ctrl.estimation.mapMediaId = media.id;
                        return saveEstimation();
                    }
                }, 1, {
                    type: 'evaluation_estimation_map',
                    idDossier: $ctrl.estimation.id,
                    uisref: {
                        name: ng2RouterStateForNgService.current.name,
                        params: ng2RouterStateForNgService.current.params,
                    },
                    title: media.file.name + " du bien en estimation " + $ctrl.estimation.bien.reference,
                    media: media
                }, {
                    method: media.cancelUpload,
                    context: media
                });

                $ctrl.options.map.loading = true;
                $ctrl.updateTasks();
                task.promise = task.promise.catch($ctrl.updateTasksErrors).finally(function () {
                    $ctrl.updateTasks();
                    $ctrl.options.map.loading = false;
                });
            }

            function onClickedEstimationOnsaleReference({action, value}: IDropdownClicked) {
                const {estimationOnsaleReference} = value as { estimationOnsaleReference: EstimationOnsaleReference };

                if (action === EstimationOnsaleReferenceDropdownComponent.actions.SEE) {
                    ng2SlideOverService.open$(DCEstimationOnsaleReferenceSlideOverComponent, {estimationOnsaleReference}).pipe(take(1)).subscribe();
                } else if (action === EstimationOnsaleReferenceDropdownComponent.actions.UN_LINK) {
                    ng2ModalService.openConfirmation$({
                        buttonConfirmationLabel: 'Supprimer',
                        question: 'Voulez-vous vraiment supprimer cette référence en vente ?',
                        title: 'Suppression d\'une référence',
                        status: ModalService.status.WARNING,
                    }).pipe(
                        switchMap(isAccepeted => {
                            if (!isAccepeted) {
                                return of(undefined);
                            }

                            return ng2EstimationOnsaleReferenceFactory.delete$($ctrl.ng2Estimation, estimationOnsaleReference).pipe(
                                tap(() => refreshCEstimationOnsaleReferencesSource.next()),
                            );
                        }),
                        take(1),
                    ).subscribe();
                }
            }

            /**
             * Remove map
             */
            function removeMap() {
                $ctrl.estimation.mapMediaId = undefined;
                saveEstimation();
            }

            /**
             * Save estimation
             *
             * @returns {Promise}
             */
            function save() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) {
                    soqSweetAlert.warningMessage($translate.instant("formulaire.invalid.TITLE"), $translate.instant("formulaire.invalid.MESSAGE"));

                    return $q.reject();
                }

                return saveEstimation();
            }

            /**
             * Save estimation
             *
             * @return {Promise}
             */
            function saveEstimation() {
                $ctrl.saving = true;

                return $ctrl.estimation.save().finally(() => $ctrl.saving = false);
            }

            function searchCityscanOnsaleReferences() {
                ng2RouterStateForNgService.navigateByUrl('/app/estimations/' + $ctrl.estimation.id + '/evaluation/onsale-references/criteres');
            }

            function updateValeurVenalePondereeCalculeeFromSurfacePonderee() {
                ng2ModalService.openConfirmation$({
                    buttonConfirmationLabel: 'Mettre à jour',
                    comments: 'En mettant à jour le "Prix moyen du m²", la "Valeur vénale" de votre bien en ' +
                        'estimation sera également impactée.',
                    question: 'Souhaitez-vous mettre à jour le "Prix moyen du m²" ?',
                    title: 'Mise à jour des données',
                    status: ModalService.status.WARNING,
                }).subscribe(isAccepted => {
                    if (isAccepted) {
                        $ctrl.changeValeurVenalePondereeCalculee();
                    }
                });
            }

            /**
             * View map
             */
            function viewMap() {
                ng2MediaFactory.get$($ctrl.estimation.mapMediaId.toString()).pipe(
                    switchMap(media => ng2MediaFactory.read$(media)),
                    take(1),
                ).subscribe();
            }
        }
    })(angularJS);
}
