<div class="panel panel-white no-radius">
  <div class="panel-heading border-bottom">
    <h4 class="panel-title text-bold"><app-noteo-icon-text-inot [label]="'Envoi à ' + INOT_LABEL"/></h4>

    @if (cDossierExports$ | async; as cDossierExports) {
      @if (cDossierExports.total > 0) {
        <div class="panel-tools">
          <button type="button" class="tw-btn-tertiary-info tw-py-1 tw-px-2" (click)="updateCDossierExports()">
            <fa-icon [icon]="'sync-alt'"/>
          </button>
        </div>
      }
    }
  </div>

  @if (etude$ | async; as etude) {
    @if (etude.settingsGenapi.inotEnabled) {
      @if (cDossierExports$ | async; as cDossierExports) {
        @if (cDossierExports.total > 0) {
          <table id="dossierExports" class="tw-w-full tw-table-fixed">
            <tbody class="tw-divide-y tw-divide-gray-200">
              @for (dossierExport of cDossierExports.results; track dossierExport) {
                <tr>
                  <td class="tw-px-4 tw-py-2">
                    <app-user-cartouche [link]="dossierExport.linkCreator"
                                        class="tw-block tw-h-8 tw-max-w-full tw-w-fit"/>
                  </td>

                  <td class="tw-w-24 tw-px-4 md:tw-px-0 tw-py-2">
                    {{ dossierExport.proprietaireContacts.length > 0 ? (dossierExport.proprietaireContacts.length > 1 ? (dossierExport.proprietaireContacts.length + ' propriétaires,') : '1 propriétaire,') : '' }}
                    {{ dossierExport.acquereurContacts.length > 0 ? (dossierExport.acquereurContacts.length > 1 ? (dossierExport.acquereurContacts.length + ' acquéreurs,') : '1 acquéreur,') : '' }}
                    {{ dossierExport.medias.length > 1 ? (dossierExport.medias.length + ' documents') : '1 document' }}
                  </td>

                  <td class="tw-w-40 tw-px-4 tw-py-2">
                    <div class="tw-flex tw-flex-col tw-items-end tw-gap-1 tw-truncate">
                      <app-dossier-inot-export-statut [dossierExport]="dossierExport"/>

                      @if (dossierExport.isDraft()) {
                        <span class="tw-pr-2 tw-text-2xs">
                          le {{ dossierExport.createdAt | date:'mediumDate' }} à
                          {{ dossierExport.createdAt | date:'shortTime' }}
                        </span>
                      }

                      @if (dossierExport.isSent()) {
                        <span class="tw-pr-2 tw-text-2xs">
                          le {{ dossierExport.processedAt | date:'mediumDate' }} à
                          {{ dossierExport.processedAt | date:'shortTime' }}
                        </span>
                      }
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        } @else {
          <div class="panel-body">
            Tout est prêt pour effectuer votre premier envoi à <app-noteo-icon-text-inot/>.
          </div>
        }
      } @else {
        <app-loader [classHeight]="'tw-h-10'" class="panel-body tw-block"/>
      }
    } @else {
      <div class="panel-body tw-w-full tw-text-sm tw-text-center tw-font-semibold">
        <div class="tw-mb-2">
          Découvrez comment envoyer votre dossier directement dans <app-noteo-icon-text-inot/>
        </div>

        <app-noteo-contact [module]="moduleInot" [options]="{withModuleInactif: false}" class="tw-block tw-mt-4"/>
      </div>
    }
  } @else {
    <app-loader [classHeight]="'tw-h-10'" class="tw-block"/>
  }

  <div class="panel-footer">
    @if (etude$ | async; as etude) {
      @if (etude.settingsGenapi.inotEnabled) {
        <button type="button" class="tw-font-bold tw-text-green-800 disabled:tw-text-inherit disabled:tw-opacity-75"
                (click)="create()">Envoyer à {{ INOT_LABEL }}</button>
      } @else {
        <div class="tw-flex tw-items-center tw-gap-1 tw-text-gray-400 tw-text-xs ng-star-inserted">
          <div class="tw-h-2 tw-w-2 tw-rounded-full tw-bg-orange-400"></div> Module {{ INOT_LABEL }} inactif
        </div>
      }
    } @else {
      <app-loader [classHeight]="'tw-h-4'" class="tw-block"/>
    }
  </div>
</div>
