import {Component, Input} from '@angular/core';
import {NoteoModule} from '@shared/noteo/noteo.module';

@Component({
    // @todo Supprimer l'import de NoteoModule pour préférer seulement AppNoteoIconTextComponent quand celui-ci sera standalone
    imports: [NoteoModule],
    selector: 'app-noteo-icon-text-notestim',
    standalone: true,
    templateUrl: 'noteo-icon-text.notestim.component.html',
})
export class AppNoteoIconTextNotestimComponent {
    static readonly label = 'Notestim';
    static readonly srcImage = '/assets/images/septeo/logo.svg';
    private _label = AppNoteoIconTextNotestimComponent.label;
    private _srcImage = AppNoteoIconTextNotestimComponent.srcImage;

    get label(): string {
        return this._label;
    }

    @Input()
    set label(value: string) {
        this._label = value;
    }

    get srcImage(): string {
        return this._srcImage;
    }

    @Input()
    set srcImage(value: string) {
        this._srcImage = value;
    }
}
