import {Component, Input} from '@angular/core';
import {AppNoteoIconTextNotyComponent} from '@shared/noteo/icon-text/noty/noteo-icon-text.noty.component';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

interface IPublicite {
    arguments: IArgument[];
    imgList: string;
    titre: string;
}

interface IArgument {
    description: string;
    icon: IconProp;
    titre: string;
}

const modules = {
    DEMANDEURS: 'demandeurs',
    ESTIMATIONS: 'estimations',
    ESTIMATION_EVALUATION_NOTESTIM: 'estimation_evaluation_notestim',
    LOCATIONS: 'locations',
    VENTES: 'ventes',
};
const publicites: Record<string, IPublicite> = {
    [modules.DEMANDEURS]: {
        arguments: [
            {
                description: 'Saisissez les critères de recherche voulus par votre demandeur.',
                icon: 'plus',
                titre: 'Créer des demandeurs',
            },
            {
                description: 'Laissez ' + AppNoteoIconTextNotyComponent.label +
                    ' rechercher pour vous les biens correspondants à votre demandeur.',
                icon: 'check-square',
                titre: 'Trouver des biens',
            },
            {
                description: 'Sélectionnez et envoyez automatiquement les résultats de la recherche.',
                icon: 'envelope',
                titre: 'Envoyer les biens',
            },
            {
                description: 'Créer rapidement un bon de visite et/ou une offre d\'achat.<br>' +
                    'Ensuite, faites signer votre document électroniquement aux différentes parties.',
                icon: 'file-lines',
                titre: 'Signer',
            },
        ],
        imgList: 'assets/images/modules/demandeurs.png',
        titre: 'Gérez vos demandeurs',
    },
    [modules.ESTIMATIONS]: {
        arguments: [
            {
                description: 'Saisissez une fiche très détaillée de votre bien afin de pouvoir en établir sa valeur rapidement.' +
                    '<br>Renseignez les propriétaires et contactez-les via ' + AppNoteoIconTextNotyComponent.label + '.',
                icon: 'plus',
                titre: 'Créer des biens en estimation',
            },
            {
                description: 'Comparez votre bien par rapport au marché actuel et établissez une valeur vénale cohérente.',
                icon: 'chart-line',
                titre: 'Évaluer le bien',
            },
            {
                description: 'Sélectionnez un modèle et il se remplit avec vos données.<br>' +
                    'Envoyez-le par email facilement au propriétaire.',
                icon: 'edit',
                titre: 'Éditer un rapport',
            },
            {
                description: 'Transférez votre bien en estimation vers la vente en 1 clic.',
                icon: 'home',
                titre: 'Créer un bien à vendre',
            },
        ],
        imgList: 'assets/images/modules/estimations.png',
        titre: 'Gérez vos biens en estimation',
    },
    [modules.ESTIMATION_EVALUATION_NOTESTIM]: {
        arguments: [
            {
                description: 'Réduisez votre temps de saisie, de recherche de comparables et également de rédaction du rapport.',
                icon: ['far', 'stopwatch'],
                titre: 'Gagner du temps',
            },
            {
                description: 'Générez un avis de valeur marché, locative ou un dossier acquéreur pour répondre aux besoins de vos clients',
                icon: ['far', 'bullseye-pointer'],
                titre: 'Créer un rapport',
            },
            {
                description: 'Amortissez très rapidement Notestim en facturant plus facilement vos avis de valeur.',
                icon: ['far', 'chart-line-up'],
                titre: 'Augmenter la rentabilité',
            },
            {
                description: 'Augmentez la valeur perçue de votre travail par vos clients grâce à un dossier complet et de qualité.',
                icon: ['far', 'thumbs-up'],
                titre: 'Satisfaire le propriétaire',
            },
        ],
        imgList: 'assets/images/modules/estimation_evaluation_notestim.png',
        titre: 'Valorisez vos estimations immobilières',
    },
    [modules.LOCATIONS]: {
        arguments: [
            {
                description: 'Saisissez seulement les critères principaux ou une fiche très détaillée de votre bien.' +
                    '<br>Renseignez les propriétaires et contactez-les via ' + AppNoteoIconTextNotyComponent.label + '.',
                icon: 'plus',
                titre: 'Créer des biens en location',
            },
            {
                description: 'Sélectionnez un modèle de gestion ou de location et il se remplit avec vos données.',
                icon: 'edit',
                titre: 'Éditer des mandats',
            },
            {
                description: 'Indiquez la disponibilité de votre bien.<br>' +
                    'Saisissez les locataires et associez leur un état des lieux.<br>' +
                    'Retrouvez l\'historique de vos locataires saisis.',
                icon: 'key',
                titre: 'Gérer les locataires',
            },
            {
                description: 'Diffusez votre bien en 1 clic vers vos sites partenaire.',
                icon: 'rss',
                titre: 'Diffuser sur les sites Internet',
            },
        ],
        imgList: 'assets/images/modules/locations.png',
        titre: 'Gérez vos biens en location',
    },
    [modules.VENTES]: {
        arguments: [
            {
                description: 'Saisissez seulement les critères obligatoires ou une fiche très détaillée de votre bien.' +
                    '<br>Renseignez les propriétaires et contactez-les via ' + AppNoteoIconTextNotyComponent.label + '.',
                icon: 'plus',
                titre: 'Créer des biens en vente',
            },
            {
                description: 'Sélectionnez un modèle et il se remplit avec vos données.' +
                    '<br>Créez un avenant aussi facilement pour toutes modifications d\'un mandat.',
                icon: 'edit',
                titre: 'Éditer des mandats',
            },
            {
                description: 'Faites signer un mandat en 2 clics.<br>' +
                    'Retrouvez votre mandat signé dans les documents du dossier avec toutes les signatures.',
                icon: 'feather-alt',
                titre: 'Signer électroniquement un mandat',
            },
            {
                description: 'Diffusez votre bien en 1 clic vers vos sites partenaire.',
                icon: 'rss',
                titre: 'Diffuser sur les sites Internet',
            },
        ],
        imgList: 'assets/images/modules/ventes.png',
        titre: 'Gérez vos mandats de vente',
    },
};

@Component({selector: 'app-publicites', templateUrl: 'publicites.component.html'})
export class AppPublicitesComponent {
    static readonly modules = modules;
    private _publicite!: IPublicite;

    @Input()
    set module(value: string) {
        this._publicite = publicites[value];
    }

    get publicite(): IPublicite {
        return this._publicite;
    }
}
