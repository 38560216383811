import {Component, Input} from '@angular/core';
import Etude from '@models/etudes/etude/etude.model';

@Component({selector: 'app-noteo-logo-notestim', templateUrl: 'noteo-logo.notestim.component.html'})
/**
 * @deprecated Use AppNoteoIconTextNotestimComponent
 */
export class AppNoteoLogoNotestimComponent {
    private _label = Etude.enablableModules.NOTESTIM.label;
    private _srcImage = Etude.enablableModules.NOTESTIM.srcImage;

    get label(): string {
        return this._label;
    }

    @Input()
    set label(value: string) {
        this._label = value;
    }

    get srcImage(): string {
        return this._srcImage;
    }

    @Input()
    set srcImage(value: string) {
        this._srcImage = value;
    }
}
