@if (currentUser$ | async; as currentUser) {
  @if (currentUser.hasRoleEstimationNotestimV2()) {
    NotestimV2 À FAIRE
  } @else {
    <app-ng-estimation-evaluation/>

    <div class="tw-flex tw-justify-center tw-max-w-[45rem] tw-mx-auto">
      <div class="tw-flex tw-justify-center tw-items-center tw-gap-8 tw-bg-blue-100 tw-border tw-border-blue-300 tw-rounded-lg tw-shadow-lg tw-py-4 tw-px-8 tw-text-sm tw-font-semibold">
        <div class="tw-w-60">Gagnez du temps sur vos évaluations grâce à <app-noteo-icon-text-notestim/></div>

        <app-noteo-contact [module]="moduleNotestim"/>
      </div>
    </div>
  }
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
}
